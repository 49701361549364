// Overflow Hidden
.o-hidden {
  overflow: hidden !important;
}

.fluid 
{
	width: 100%;
	height: 100%;
}

.center-parent 
{
	position: relative;
}

.center-child {
	position: absolute;
	top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 0.2;
}
.opacity-2 {
  opacity: 0.4;
}
.opacity-3 {
  opacity: 0.6;
}
.opacity-4 {
  opacity: .8;
}
.opacity-5 {
  opacity: 1;
}