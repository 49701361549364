
.right 
{
  right: 0;
}

.left 
{
  left: 0;
}

.top 
{
  top: 0;
}

.bottom 
{
  bottom: 0;
}