.submit-page {
    .submit-header {
        color: white;
        background: linear-gradient(90deg,#3158d1, #1b36c0);
        background-color: #2647c8;
        padding: 121px 20px;

        .title {
            margin-bottom: 22px;
        }

        p {
            margin-bottom: 0;
        }
    }
}



@include media-breakpoint-up(lg) {
    .submit-page {
        .submit-header {
            padding: 161px 315px;
        }
    }

}