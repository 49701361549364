@import "partials/topbar.scss";
@import "partials/footer.scss";
@import "partials/header.scss";

.modal {

    .cookies-modal {
        .modal-content {
            .modal-body {
                padding: 10px 5px;
                display: flex;
                flex-direction: column;
            }
            
            .cookie-settings {
                .cookie-item {
                    margin-bottom: 10px
                }
                .cookie-item:last-of-type {
                    margin-bottom: 0px
                }

                .subtitle {
                    font-weight: 700;
                    font-size: 18px;
                }
                .text {
                    padding: 5px;
                    font-size: 14px;
                }
                .form-check-input {
                    width: 35px;
                    height: 20px;
                }
                .form-check-input:checked {
                    background-color: #2BC767;
                    border-color: #2BC767;
                }
                .btn.btn-dropdown {
                    background-color: #ffffff;
                    color: #1B1F27;
                    padding: 5px;
                }
                .btn.btn-dropdown i{
                    background-image: url(/img/icons/arrow_1.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 15px;
                    height: 15px;
                    display: block;
                    transform: rotate(-180deg);
                    transition: transform 0.2s ease-in-out;
                }
                .btn.btn-dropdown.collapsed i{
                    transform: rotate(0deg);
                }

                .cookie-description{
                    border-radius: 0;
                    border: none;
                    font-size: 12px;
                    padding: 0;

                    .cookie-row {
                        background-color: #F5F4F9;
                        margin-bottom: 5px;
                        padding: 5px;
                    }
                }
            }

            .cookie-btns {
                flex-wrap: wrap-reverse;
                .btn {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
            border-radius: 0 !important;
            padding: 10px 20px;
            color: #1B1F27;

            .title {
                font-weight: 700;
                font-size: 26px;
                margin-bottom: 15px;
            }
            .text {
                font-size: 16px;
            }

            .btn {
                font-size: 16px;
                background-color: #1B1F27;
                color: #ffffff;
                border-radius: 0;
                font-weight: 700;
                text-transform: uppercase;
                padding: 5px 15px;

            }

            .btn.decline {
                background-color: #ffffff;
                color: #1B1F27;
            }

            .btn.decline:hover {
                background-color: #1B1F27;
                color: #ffffff;
            }

            .btn.accept {
                background-color: #2BC767;
            }
            .btn.accept:hover {
                background-color: #1B1F27;
            }
            .btn.settings:hover {
                background-color: #2BC767;
            }

        }
    }
}


@include media-breakpoint-up(lg) {
    .modal {
        .cookies-modal {
            .modal-content {
                .modal-body {
                    padding: 20px 10px;
                    display: flex;
                    flex-direction: column;
                }
                
                .cookie-settings {
                    .cookie-item {
                        margin-bottom: 10px
                    }
                    .cookie-item:last-of-type {
                        margin-bottom: 0px
                    }
    
                    .subtitle {
                        font-weight: 700;
                        font-size: 22px;
                    }
                    .text {
                        padding: 5px;
                        font-size: 14px;
                    }
                    .form-check-input {
                        width: 50px;
                        height: 25px;
                    }
                    .form-check-input:checked {
                        background-color: #2BC767;
                        border-color: #2BC767;
                    }
                    .btn.btn-dropdown {
                        background-color: #ffffff;
                        color: #1B1F27;
                        padding: 5px;
                    }
                    .btn.btn-dropdown i{
                        background-image: url(/img/icons/arrow_1.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 20px;
                        height: 20px;
                        display: block;
                        transform: rotate(-180deg);
                        transition: transform 0.2s ease-in-out;
                    }
                    .btn.btn-dropdown.collapsed i{
                        transform: rotate(0deg);
                    }
    
                    .cookie-description{
                        border-radius: 0;
                        border: none;
                        font-size: 12px;
                        padding: 0;
    
                        .cookie-row {
                            background-color: #F5F4F9;
                            margin-bottom: 5px;
                            padding: 5px;
    
                        }
                    }
                }
    
    
                border-radius: 0 !important;
                padding: 20px 40px;
                color: #1B1F27;
                .title {
                    font-weight: 700;
                    font-size: 40px;
                    margin-bottom: 15px;
                }
                .text {
                    font-size: 22px;
                }
    
                .btn {
                    font-size: 16px;
                    background-color: #1B1F27;
                    color: #ffffff;
                    border-radius: 0;
                    font-weight: 700;
                    padding: 10px 28px;
                }
    
                .btn.decline {
                    background-color: #ffffff;
                    color: #1B1F27;
                }
                .btn.decline:hover {
                    background-color: #1B1F27;
                    color: #ffffff;
                }
    
                .btn.accept {
                    background-color: #2BC767;
                }
            }
        }
    }
    
}