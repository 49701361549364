footer {
  background-color: #1B1F27;
}

.footer-menu {

  background-color: #1B1F27;

  padding: 30px 20px;
  padding-top: 18px;

  font-size: 14px;

  display: flex;
  flex-direction: column;
 

  .footer-row {
    display: flex;
    width: 70%;
    justify-content: space-between;
  }

  .footer-socials {
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    

    margin-top: 26px;

    .icon {
      width: 40px;
      height: 40px;

      margin-right: 20px;
    }

    a:hover {
      opacity: 0.6;
    }
  }

  .title {
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .link {
    color: #ACACAC;
    margin-bottom: 5px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .link:hover, .link.highlighted{
    color: white;
    text-decoration: underline;
  }

  .link:last-child {
    margin-bottom: 0;
  }

  .accordion {
    background-color: inherit;



    .accordion-item {
      background-color: inherit;

      border: none;
      border-bottom: 2px solid #6F6F6F;
      .accordion-header {

          .accordion-button {
            background-color: inherit;
            color: white !important;
            border: none;
            font-weight: 700;

            padding: 0;
            padding-bottom: 14px;
            padding-top: 12px;

            font-size: 14px;
          }

          .accordion-button:focus{
            box-shadow: none;
          }
          
          .accordion-button::after {
            background-image: url(/img/icons/arrow_2.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 12px;
            height: 12px;
          }
      }
    }
    .accordion-item:last-child {
      border: none;
    }
  }
}

.footer-border {
  height: 1px;
  border-bottom: 1px solid #363739;
}

.footer-bar {

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #343434;
  color: #ACACAC;
  padding: 15px 20px;
  font-size: 14px;
  vertical-align: middle;

  text-align: center;

  .copyright {
    line-height: normal;
    margin-bottom: 15px;
  }

  .cookies {
      
    display: flex;
    flex-direction: column;
    div {
      margin-bottom: 15px;
    }
    div:last-child{
      margin-bottom: 0;
    }
    .link {
      cursor: pointer;
    }

    .link:hover{
      color: white;
      text-decoration: underline;
    }
  }

}

.footer-adventure {
  position: relative;
  overflow: hidden;

  background-color: #1B1F27;

  padding-top: 70px;
  padding-bottom: 300px;
  .text-card {
      position: relative;
      background-color: #313847;
      color: white;
      padding: 30px 40px;
      margin-right: auto;
      margin-left: auto;
      z-index: 0;
      min-width: none;
      width: 85%;

      .title {
          font-weight: 700;
          font-size: 28px;
          margin-bottom: 20px;
      }

      .text {
          font-size: 14px;
          margin-bottom: 30px;

          .text-highlighted {
              font-weight: 700;
              color: #2BC767
          }
      }

      .btn {
          font-weight: 700;
          font-size: 16px;
          background-color: #2BC767;
          color: white;
          border-radius: 0;
          padding: 14px 28px;
      }
      .btn:hover {
        background-color: #1B1F27;

      }
  }

  .image-background-sm {
    display: block;
    position: absolute;
    min-width: 383px;
    min-height: 383px;
    width: 383px;
    height: 383px;
    background-color: #252B36;
    border-radius: 50%;
    top: 100%;

    left: 90%;
    transform: translate(-50%, -100%);
    z-index: 0;

  }

  .image-background {
    position: absolute;
    min-width: 383px;
    min-height: 383px;
    width: 383px;
    height: 383px;
    background-color: none;
    top: 100%;
    left: 90%;
    transform: translate(-50%, -100%);
    z-index: 1;
    pointer-events: none;
  }

  img {
    position: absolute;
    width: 75%;
    min-width: 216px;
    height: auto;
    top: calc(100% - 50px);
    left: 40%;
    transform: translate(-50%, -100%);
  }

}

.footer-benefits {
  background-color: #1B1F27;
  color: white;
  padding: 30px 40px;

  .cell.cell-header {
    .title {
      font-size: 28px;
      margin-top: 0;
      margin-bottom: 0;
    }

  }
  .title {
    font-weight: 700;
    text-align: left;
  }
  
  .cell {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    margin-bottom: 25px;

    .title {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .icon {
      width: 38px;
      height: 38px;
    }
    .text {
      font-size: 14px;
    }
  }
}

#JOBS{
  cursor: pointer;
}

@include media-breakpoint-up(lg) {

  .footer-border {
    margin: 0 119px;
  }

  .footer-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 20px 120px;
    font-size: 14px;
  
    .cookies {
      
      display: flex;
      flex-direction: row;

      div:first-child{
        margin-right: 60px;
      }
      div {
        margin-bottom: 0;

      }

    }

    .copyright {
      margin-bottom: 0;
    }

  }

  .footer-menu {
    background-color: #1B1F27;
  
    padding: 40px 119px;
  
    font-size: 14px;
  
    display: flex;
    flex-direction: row;
  
    .footer-row {
      display: flex;
      width: 70%;
      justify-content: space-between;
    }
  
    .footer-socials {
      margin-left: auto;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 0;
      
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 0;

      }
  
      a:hover {
        opacity: 0.6;
      }
    }
  
    .title {
      color: white;
      font-weight: 700;
      margin-bottom: 10px;
    }
  
    .link {
      color: #ACACAC;
      margin-bottom: 5px;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  
    .link:hover, .link.highlighted{
      color: white;
      text-decoration: underline;
    }
  
    .link:last-child {
      margin-bottom: 0;
    }
  }

  .footer-adventure {
    position: relative;
    overflow: hidden;
  
    background-color: #1B1F27;
  
    padding-top: 100px;
    padding-bottom: 100px;
  
    .text-card {
        position: relative;
        background-color: #313847;
        color: white;
        padding: 70px 80px;
        margin-right: auto;
        margin-left: 0;

        z-index: 20;
        min-width: 600px;
        width: 80%;
  
        .title {
            font-weight: 700;
            font-size: 54px;
            margin-bottom: 30px;
        }
  
        .text {
            font-size: 18px;
            margin-bottom: 30px;
  
            .text-highlighted {
                font-weight: 700;
                color: #2BC767
            }
        }
  
        .btn {
            font-size: 18px;
            background-color: #2BC767;
            color: white;
            border-radius: 0;
            padding: 14px 28px;
        }
    }
  
    
    .image-background {
      position: absolute;
      min-width: 1024px;
      min-height: 1024px;
      width: 50vw;
      height: 50vw;
      background-color: #252B36;
      border-radius: 50%;
      top: 80%;
      left: 0;
      transform: translate(-20%, -100%);
    }

    .image-background-sm {
      display: none;
    }

    
  
    img {
      position: absolute;
      z-index: 2;
      width: 25%;
      min-width: 371px;
      // top: 60%;
      // left: 50%;
      top: calc(100% - 50px);
      left: 50%;
      transform: translate(-50%, -100%);
    }
  
  }

  .footer-benefits {
    padding: 100px 90px;
  
    .cell.cell-header {
      
      .title {
        font-size: 54px;
        max-width: 300px;
      }
  
    }
    
    .cell {
      margin-bottom: 70px;
      
      padding-left: 30px;
  
      padding-right: 30px;

      .title {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .icon {
        width: 80px;
        height: 80px;
      }

      .text {
        font-size: 18px;
      }
  
    }
  }
}
