.support-page {
    .support-header {
        color: white;
        background: linear-gradient(90deg, #ef4359, #ed4458);
        background-color: #ee4459;
        padding: 121px 20px;

        .title {
            margin-bottom: 22px;
        }

        p {
            margin-bottom: 0;
        }
    }
}

.form-body {
    background-color: #f5f4f9;
    padding: 0;
    .form-container {
        background-color: #ffffff;
        width: 100%;
        padding: 70px 40px;

        .title {
            font-weight: 700;
            margin-bottom: 30px;
            font-size: 28px;
        }
        .form-select {
            background-image: url(/img/icons/arrow_1.svg);
            cursor: pointer;
        }
        .form-label {
            color: #1b1f27;
            font-weight: 700;
            font-family: 'Poppins', serif;
            line-height: 1.2;
            font-size: 12px;
            margin-bottom: 10px;

            .label-optional {
                color: #6384a5;
                font-weight: 400;
                font-size: 12px;
                margin-left: 5px;
            }
        }
        .invalid-feedback {
            display: block;
            position: absolute;
            margin-left: 0.5rem;
            font-size: 10px;
        }

        .form-row {
            margin-bottom: 20px;
        }
        .form-col {
            margin-top: 20px;
        }

        input,
        textarea,
        select {
            background-color: #f5f4f9;
            border: none;
            border-radius: 0;
            padding: 17px;
            color: #1b1f27;
            line-height: 1.2;
            font-size: 14px;
        }

        input::placeholder,
        textarea::placeholder {
            color: #cccccc;
        }

        select {
            font-weight: 700;
            padding-right: 30px;
        }

        textarea {
            height: 220px;
        }

        .form-btn {
            background-color: #000000;
            color: #ffffff;
            border-radius: 0;
            border: none;
            font-weight: 700;
            font-family: 'Poppins', serif;
            line-height: 1.2;
            font-size: 16px;
            padding: 14px 90px;
            margin-top: 10px;
        }
        .form-btn:hover {
            background-color: #2bc767;
        }
    }
}

@include media-breakpoint-up(lg) {
    .support-page {
        .support-header {
            padding: 161px 315px;
        }
    }
    .form-body {
        padding: 100px 120px;

        .form-container {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;

            padding: 70px 90px;

            .title {
                font-size: 40px;
            }

            .form-label {
                font-size: 16px;
                text-wrap: nowrap;
                .label-optional {
                    font-size: 16px;
                }
            }
            .invalid-feedback {
                font-size: 12px;
            }

            .form-row {
                margin-bottom: 30px;
            }

            .form-col {
                margin-top: 0px;
            }

            .form-input-left {
                padding-right: 15px;
            }

            .form-input-right {
                padding-left: 15px;
            }

            input,
            textarea,
            select {
                font-size: 22px;
            }

            .form-btn {
                margin-left: auto;
                padding: 14px 90px;
            }

            textarea {
                height: 236px;
            }
        }
    }
}
