.games-page {
    .games-header {
        background: linear-gradient(90deg, #2BCB69, #1EC25E);
        background-color: #23C662;
        color: #ffffff;
        padding: 138px 20px;

        .title {
            
            margin-bottom: 22px;
        }
        .subtitle {
            width: 80%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            margin-bottom: 0;
        }
    }

    .games-content {
        padding: 70px 20px;

        display: flex;
        flex-direction: column;

        .title {
            font-weight: 700;
            color: #1B1F27;
            text-align: center;

            font-size: 22px;
            margin-bottom: 30px;
        }

        .games-btn {
            color: #1B1F27;
            text-transform: uppercase;
            font-weight: 700;

            font-size: 16px;

            border: 3px solid #1B1F27;

            padding: 10px 50px;
            border-radius: 0;

            margin-left: auto;
            margin-right: auto;
        }

        .games-btn:hover {
            background-color: #1B1F27;
            color: #ffffff;
        }
    
        .games-list {
            margin-top: 0;
            margin-bottom: 70px;

            .game-card:nth-child(even) .game{
                margin-left: 5%;
                margin-right: auto;

            }
            .game-card:nth-child(odd) .game{
                margin-right: 5%;
                margin-left: auto;
            }

            .game-card {
                .game {
                    margin-top: 25px;
                    width: 100%;
                    max-width: 200px;

                    overflow: hidden;
                    .thumbnail {
                        width: 100%;
                        height: auto;
                    }
        
                    .title {
                        margin-top: 10px;
                        margin-bottom: 3px;
                        
                        color: #1B1F27;
                        font-size: 16px;
                        font-weight: 700;
                        text-align: left;

                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .subtitle {
                        margin-top: 3px;
                        color: #6384A5;
                        font-size: 12px;
                        font-weight: 400;
                        text-align: left;
                    }
        
                    .icon-playmarket {
                        height: 21px !important;
                    }
        
                    .links {
                        margin-top: 4px;
                    }
        
                    .link {
                        margin-right: 5px;
                        color: #7995B2;
                        cursor: pointer; 
                        width: 20px;
                        height: 20px;
                    }
                }

         
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .games-page {
        .games-content {
            .games-list {
                margin-top: 0px;
                margin-bottom: 0px;
        
                .game-card:nth-child(even) .game{
                    margin-left:0;
                    margin-right: 0;
                }
                .game-card:nth-child(odd) .game{
                    margin-right:0;
                    margin-left: 0;
                }
                .game-card {
                    justify-content: center;
                    margin-bottom: 70px;
        
        
                    // .game {
                    //     margin-top: 0;
                    //     width: auto;
                    //     max-width: none;
                    //     .thumbnail {
                    //         width: 242px;
                    //     }
            
                    //     .title {
                    //         margin-top: 20px;
                    //         margin-bottom: 0;
                    //     }
                    // }
                }
            }
        }
    }


}
@include media-breakpoint-up(lg) {
    .games-page {
        .games-header {
    
            padding: 161px 315px;
    
            .title {
                margin-bottom: 22px;
            }
            .subtitle {
                width: 100%;
            }
        }
    
        .games-content {
            padding: 70px 119px;
    
    
            .title {
                font-size: 40px;
                margin-bottom: 40px;
            }
    
            .games-btn {
                font-size: 16px;
    
    
                padding: 10px 50px;

                margin-left: auto;
                margin-right: auto;
            }
        
            .games-list {
                margin-top: 0px;
                margin-bottom: 0px;

                max-width: 1800px;
                margin-left: auto;
                margin-right: auto;

                .game-card:nth-child(even) .game{
                    margin-left:0;
                    margin-right: 0;
                }
                .game-card:nth-child(odd) .game{
                    margin-right:0;
                    margin-left: 0;

                }
                .game-card {
                    justify-content: center;
                    margin-bottom: 70px;

    
                    .game {
                        margin-top: 0;
                        width: auto;
                        max-width: none;
                        .thumbnail {
                            width: 242px;
                        }
            
                        .title {
                            margin-top: 20px;
                            font-size: 22px;
                            margin-bottom: 0;
                        }
                        .subtitle {
                            font-size: 12px;
                        }
            
                        .icon-playmarket {
                            height: 21px !important;
                        }
                    }
                }
            }
        }
    }
}