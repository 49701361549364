.blinking-cursor {
  -webkit-animation: 1s blink infinite;
  -moz-animation: 1s blink infinite;
  -ms-animation: 1s blink infinite;
  -o-animation: 1s blink infinite;
  animation: 1s blink infinite;
}

@keyframes blink {
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}