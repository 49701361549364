body {
    letter-spacing: 0px !important;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    overflow-x: hidden;

}

.font-header {
    font-family: 'Poppins', sans-serif;
    line-height: 1.2;
}

.main {
    overflow-x: hidden;
}

.home-page {
    .home-content {
        background-color: #ffffff;
    }
    .home-header {
        background-image: url(/img/home/home.webp);
        background-color: #cbd7f0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;  
        padding: 80px 30px;
        
        overflow: hidden;
        .video-container {
            position: relative;

        }
        .header-video {
            
            height: 800px;

            clip-path: inset(1px 1px);

            // min-height: 80vh;
            object-fit: cover;
            object-position: center;

            position: absolute;
            // float: left;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;

            left: calc(100% + 30px); 
            top: 50%;
            transform: translate(-100%, -50%); 
        }



        .title {
            font-weight: 700;
            font-size: 28px;
            color: #1B1F27;
            padding-left: 20px;
            padding-right: 20px;
            z-index: 1;
        }
    
        .subtitle {
            margin-top: 20px;
            font-size: 16px;
            color: #7B88A8;
    
            margin-left: 10px;
            margin-right: 10px;
    
            text-align: center;
            z-index: 1;

        }
    
        .btn {
            margin-top: 40px;
            font-size: 16px;
     
    
            background-color: #1b1f27;
            color: $white;
            padding: 14px 60px;
            font-weight: 700;
            border-radius: 0;
            z-index: 1;

        }
        .btn:hover{
            background-color: #2BC767;
        }
    }
    
    .home-games {
        padding-top: 70px;
        z-index: 2;
        background-color: #ffffff;
        .title {
            font-weight: 700;
            font-size: 22px;
            color: #1B1F27
        }
    
        .featured-games {
            margin-top: 25px;
            padding: 0 20px;

            .game-card:nth-child(even) .game{
                margin-left: 5%;
                margin-right: auto;

            }
            .game-card:nth-child(odd) .game{
                margin-right: 5%;
                margin-left: auto;
            }

            .game {
                margin-top: 25px;
    
                .thumbnail {
                    width: 100%;
                    height: auto;
                }
    
                .title {
                    margin-top: 10px;
    
                    color: #1B1F27;
                    font-size: 16px;
                    font-weight: 700;
                    text-align: left;
                }
                .subtitle {
                    margin-top: 3px;
                    color: #6384A5;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: left;
                }
    
                .icon-playmarket {
                    height: 21px !important;
                }
    
                .links {
                    margin-top: 4px;
                }
    
                .link {
                    margin-right: 5px;
                    color: #7995B2;
                    cursor: pointer; 
                    width: 20px;
                    height: 20px;
                }
            }
        }
    
    }
    
    .home-stats {
        background-color: #F5F4F9;
    
        margin-top: 70px;
    
        padding: 75px 50px;
    
        .stat {
            position: relative;
            background-color: white;
            margin-bottom: 53px;
            padding: 32px 75px;
    
            .icon {
                position: absolute;
                top: -23px;
                left: calc(50% - 23px);
                width: 46px;
                height: 46px;
            }
    
            .title {
                margin-top: 15px;
                font-weight: 700;
                font-size: 26px;
                color: #1B1F27;
                align-self: center;
            }
    
            .subtitle {
                color: #7B88A8;
                font-size: 16px;
                font-weight: 400;
                align-self: center;
                text-align: center;
            }
        }
        .stat-card:last-child .stat {
            margin-bottom: 0px;
        }
        
    }
    
    .home-border {
        display: block;
        background-color: #F5F4F9;
        padding: 0 20px;
        div {
            border-bottom: 1px solid #D2D8E2;
        }
    }
    
    .home-about {
        background-color: #F5F4F9;
        padding-top: 70px;
        padding-bottom: 70px;
        width: 100%;
        overflow: hidden;
    
        .title {
            font-size: 22px;
            font-weight: 700;
            color: #1B1F27;
            margin-bottom: 30px;
        }
    
        img {
            width: 100%;
            max-width: 650px;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    
        .text-card {
    
            margin-top: auto;
            margin-bottom: auto;
    
            padding: 30px 40px;
            background-color: white;
            width: 80%;
            max-width: 500px;
    
            .title {
                
                font-weight: 700;
                color: #1B1F27;
    
                font-size: 28px;
    
                margin-bottom: 16px;
            }
    
            .text {
                color: #7B88A8;
                font-weight: 400;
    
                font-size: 14px;
    
                text-align: start;
    
                .text-highlighted {
                    color: #2BC767;
                    font-weight: 700;
                }
    
                p:last-child{
                    margin-bottom: 0;
                }
            }
        }
    
        .text-card-left {
            margin-top: -150px;
            margin-left: auto;
            margin-right: auto;
        }
    
        .text-card-right {
            margin-top: -150px;
            margin-left: auto;
            margin-right: auto;
            z-index: 2;
        }
    
        .row-expertise {
            flex-direction: column-reverse;
        }
    
        .home-values {
            margin-top: 113px;
            margin-left: 40px;
            margin-right: 40px;
            width: auto;
            position: relative;
            padding: 30px 40px;
    
            background-color: #2BC767;
            .title {
                font-size: 28px;
                color: white;
                margin-bottom: 20px;
            }
    
            .values-row {
                width: 100%;
                max-width: none;
            }
    
            .value-card {
                padding-right: 10%;
                margin-bottom: 15px;
                max-width: none;
                .title {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
    
                .text {
                    font-size: 14px;
                    text-align: start;
                    color: white;
    
                    p {
                        margin-bottom: 0;
                    }
                }
            }
    
            .value-card:last-child {
                margin-bottom: 0;
            }
    
            .values-img {
                position: absolute;
                right: 3%;
                top: 0;
                overflow: hidden;
                transform: translate(50%, -50%);
                width: 50%;
                z-index: 2;
    
                min-width: 256px;
                img {
                    width: 100%;
                    overflow: hidden;
    
                }
            }
        }
    }

}

@include media-breakpoint-up(md) {
    .home-page {
        .home-stats {
            padding: 75px 25px;
            .stat {
                margin-bottom: 0px;
            }
        }
        .home-games {

            .featured-games {
    
    
                .game-card:nth-child(even) .game{
                    margin-left: 0;
                    margin-right: 0;
    
                }
                .game-card:nth-child(odd) .game{
                    margin-right: 0;
                    margin-left: 0;
                }
    
                .game {
                    margin-top: 25px;
        
                    .thumbnail {
                        width: 95%;
                        max-width: 200px;
                    }
        
                    .title {
                        margin-top: 10px;
        
                        color: #1B1F27;
                        font-size: 16px;
                        font-weight: 700;
                        text-align: left;
                    }
                    .subtitle {
                        margin-top: 3px;
                        color: #6384A5;
                        font-size: 12px;
                        font-weight: 400;
                        text-align: left;
                    }
        
                    .icon-playmarket {
                        height: 21px !important;
                    }
        
                    .links {
                        margin-top: 4px;
                    }
        
                    .link {
                        margin-right: 5px;
                        color: #7995B2;
                        cursor: pointer; 
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        
        }
    }
}


@include media-breakpoint-up(lg) {
    .home-page {

        .home-header {
            padding: 116px 226px;

            .title {
                font-size: 54px;
                padding: 0;
            }

            // .video-container {
            //     // top: -116px;
            //     // left: -226px;
            // }
            .header-video {
                width: max(100vmax, 800px * 16/9);
                min-height: 600px;
                height: auto;

                left: 50%; 
                top: 50%;
                transform: translate(-50%, -50%); 
                
            }
            .header-video:focus { outline:none; }

            .subtitle {
                font-size: 22px;

                margin-left: 150px;
                margin-right: 150px;

            }

            .btn {
                margin-top: 40px;
                padding: 14px 60px;
            }
        }

        .home-border {
            display: none;
        }

        .home-stats {
            background-color: $white;

            max-width: 1370px;
            
            margin-left: auto;
            margin-right: auto;

            margin-top: 199px;
            margin-bottom: 100px;
        
            padding: 0 120px;
        
            .stat {
                position: static;
        
                .icon {
                    position: static;
                    width: 62px;
                    height: 62px;
                    align-self: center;
                }
        
                .title {
                    margin-top: 15px;
                    font-size: 46px;
                }
        
                .subtitle {
                    font-size: 22px;
                }
            }
        }

        .home-games {
            padding-top: 70px;
            max-width: 2000px;
            margin-left: auto;
            margin-right: auto;
            .title {
                font-size: 40px;
            }

            .game-card {
                justify-content: center;
            }
        
            .featured-games {
                margin-top: 60px;
                padding: 0 110px;

                .game-card:nth-child(even) .game{
                    margin-left:0;
                    margin-right: 0;
                }
                .game-card:nth-child(odd) .game{
                    margin-right:0;
                    margin-left: 0;
                }

                .game {
                    margin-top: 0;
        
                    .thumbnail {
                        width: 242px;
                        max-width: none;

                    }
        
                    .title {
                        margin-top: 20px;
                        font-size: 22px;
                    }
                    .subtitle {
                        font-size: 12px;
                    }
        
                    .icon-playmarket {
                        height: 21px !important;
                    }
                }
            }
        
        }

        .home-about {
            padding-top: 90px;
            padding-bottom: 200px;
            
            .about-container {
                max-width: 2000px;
                margin-left: auto;
                margin-right: auto;
            }

            .title {
                font-size: 40px;
                font-weight: 700;
                color: #1B1F27;
                margin-bottom: 60px;
            }
        
            img {
                width: 90%;
            }

            .text-card {
        
                margin-top: auto;
                margin-bottom: auto;
        
                padding: 70px 90px;
                background-color: white;
                width: 80%;
                max-width: 700px;
        
                .title {
                    
                    font-weight: 700;
                    color: #1B1F27;
        
                    font-size: 54px;
        
                    margin-bottom: 40px;
                }
        
                .text {
                    color: #7B88A8;
                    font-weight: 400;
        
                    font-size: 18px;
        
                    text-align: start;
        
                    .text-highlighted {
                        color: #2BC767;
                        font-weight: 700;
        
                    }
                }
            }
        
            .text-card-left {
                margin-left: -15px;
            }
        
            .text-card-right {
                margin-left: auto;
                margin-right: -15px;
            }

            .row-expertise {
                flex-direction: row;
            }

            .home-values {
                margin-top: 113px;
                margin-left: auto;
                margin-right: auto;
                width: 78%;
                max-width: 1370px;
                position: relative;
                padding: 70px 90px;
        
                background-color: #2BC767;
                .title {
                    font-size: 54px;
                    color: white;
                    margin-bottom: 38px;
                }
        
                .values-row {
                    width: 85%;
                    max-width: 1000px;
                }
        
                .value-card {
                    padding-right: 30px;
                    margin-bottom: 38px;
        
                    .title {
                        font-size: 22px;
                        margin-bottom: 5px;
                    }
        
                    .text {
                        font-size: 18px;
                        text-align: start;
                        color: white;
        
                        // p {
                        //     margin-bottom: 5px;
                        // }
                    }
                }
        
                .values-img {
                    position: absolute;
                    right: 50px;
                    bottom: -125px;
                    overflow: hidden;
                    transform: translate(50%, 0);
                    width: 526px;
        
                    img {
                        width: 100%;
                        overflow: hidden;
        
                    }
                }
            }
        }
    }
}