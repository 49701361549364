
$grid-breakpoints: (
  xs: 0,
  sm: 400px,
  md: 850px,
  lg: 1250px,
  xl: 1300px,
  xxl: 1400px
);


.icon-playmarket {
  background-image: url(/img/icons/android.svg);
}
.icon-appstore {
  background-image: url(/img/icons/apple.svg);
}
.icon-user {
  background-image: url(/img/icons/man.svg);
}
.icon-review {
  background-image: url(/img/icons/thumbs.svg);
}
.icon-expert {
  background-image: url(/img/icons/search.svg);
}
.icon-facebook {
  background-image: url(/img/icons/facebook.svg);
}
.icon-linkedin {
  background-image: url(/img/icons/linkedin.svg);
}
.icon-instagram {
  background-image: url(/img/icons/instagram.svg);
}
.icon-company {
  background-image: url(/img/footer/benefits/Company.svg);
}
.icon-grow {
  background-image: url(/img/footer/benefits/Grow.svg);
}
.icon-others {
  background-image: url(/img/footer/benefits/Others.svg);
}
.icon-progress {
  background-image: url(/img/footer/benefits/Progress.svg);
}
.icon-sports {
  background-image: url(/img/footer/benefits/Sports.svg);
}

.icon {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  vertical-align: middle;
  display: inline-block;
}



// $blue: #4e63df !default;

// $dropdown-link-active-bg: #515151 !default;

// $font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

// $font-weight-light: 300 !default;
// // $font-weight-base: 400;
// $headings-font-weight: 400 !default;

// // Borders Radius
// $border-radius: 0.35rem !default;
// $border-color: darken(#e9ecef, 2%) !default;


// // Dropdowns
// $dropdown-font-size: 0.85rem !default;
// $dropdown-border-color: $border-color !default;

// $list-group-bg: #f8f9fa !default;

// $list-group-hover-bg: #ffffff !default;

// $list-group-active-bg: #646464 !default;

// // Override Bootstrap default variables here
// // Do not edit any of the files in /vendor/bootstrap/scss/!

// // Color Variables
// // Bootstrap Color Overrides

// $gray-100: #f8f9fc !default;
// $gray-900: #5a5c69 !default;

// $box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, .2) !default;
// $box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, .15) !default;
// // $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// // Card
// $card-cap-bg: $gray-100 !default;
// $card-border-color: $border-color !default;
