.icon-1 {
	height: 13rem;
	width: 13rem;
}

.icon-2 {
	height: 10rem;
	width: 10rem;
}

.icon-3 {
	height: 3.5rem;
	width: 3.5rem;
}

.icon-4 
{
	width: 34px;
	height: 34px;
}

.icon-5 {
	height: 1.5rem;
	width: 1.5rem;
}

.icon-6 {
	height: 1.0rem;
	width: 1.0rem;
}

.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}