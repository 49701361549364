.page-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 20px;
    text-align: center;

    .title {
        font-size: 28px;
        font-weight: 700;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-up(lg) {
    .page-header {
        padding: 116px 226px;
    
        .title {
            font-size: 54px;
        }
    
        .subtitle {
            font-size: 22px;
        }
    }
}

