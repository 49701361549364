.career-page {
    background: linear-gradient(90deg, #F7F6FC, #F0EEF8);
    
    .header {
        background-image: url(/img/career/careers.jpg);
        background-color: #1B1F27;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        color: white;


        padding: 60px 20px;

        text-align: center;
        .title {
            font-size: 28px;
            font-weight: 700;

        }

        .subtitle {
            font-size: 16px;
            margin-top: 18px;
            margin-bottom: 30px;

            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

        .btn {
            text-transform: uppercase;
            background-color: white;
            padding: 15px 30px;
            color: #1B1F27;
            font-size: 16px;
            font-weight: 700;
            border-radius: 0;
        }

        .btn:hover {
            background-color: #1B1F27;
            color: #ffffff;

        }
    }

    .positions {
        padding: 70px 20px;

        margin-left: auto;
        margin-right: auto;

        .title {
            font-weight: 700;
            font-size: 22px;
            color: #1B1F27;
            text-align: center;
            margin-bottom: 10px;
        }


        .row-header {
            color: #2BC767 ;

            font-weight: 700;
            background-color: inherit !important;
            margin-bottom: 0 !important;

            font-size: 14px ;

            .col-localisation {
                border-left: none;
                color: #2BC767 !important;
                font-size: 14px !important;

            }
        }

        .row-position {
            display: flex;
            background-color: white;

            margin-bottom: 15px;
        }


        .col-title {
            width: 60%;

            display: flex;
            flex-direction: column;

            padding: 20px;

            .job-title {
                color: #1B1F27;
                font-size: 18px;
                font-weight: 700;

                margin-bottom: 10px;
            }

            .job-more a{
                color: #2BC767;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;
                cursor: pointer;
            }
            .job-more:hover {
                text-decoration: underline;
            }
        }

        .col-localisation {
            padding: 20px;
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: 1px solid #D8D8D8;

            color: #6384A5;
            font-size: 12px;

        }

    }
}

@include media-breakpoint-up(lg) {
    .career-page {

        .header {
            padding: 100px 370px;
    
            .title {
                font-size: 54px;
            }
    
            .subtitle {
                font-size: 22px;
                margin-top: 22px;
                margin-bottom: 30px;
    
                max-width: 800px;
            }
    
            .btn {
                padding: 15px 30px;
                font-size: 16px;
            }
        }

        .positions {
            padding: 70px 120px;
            max-width: 2000px;

            .title {
                font-size: 40px;
                margin-bottom: 10px;
            }

    
            .row-position {
                margin-bottom: 20px;
            }
            
            .row-header {
                font-size: 22px;
                .col-localisation {
                    font-size: 22px !important;
    
                }
            }
    
            .col-title {

    
                padding: 30px 40px;

    
                .job-title {
                    font-size: 26px;
    
                }
    
                .job-more a{
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
    
            .col-localisation {
                padding: 30px 40px;
                font-size: 18px;

            }
    
        }
    }
    
}