.app-topbar {
    padding: 20px;
    font-family: 'Poppins', serif;
    font-weight: 400;
    font-size: 16px;

    .navbar-toggler {
        border: none;
        box-shadow: none;
    }
    .navbar-toggler-icon {
        background-image: url(/img/topbar/btn_menu.svg);
    }

    .topbar-link {
        padding: 10px 20px !important;
        color: #1B1F27 !important;
        
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;

    }
    
    .topbar-btn {
        font-weight: 700;
        padding: 10px 40px !important;
        color: $white !important;
        background-color: #1B1F27;

        margin-left: auto;
        margin-right: auto;

        margin-top: 10px;
 
    }

    .topbar-link:hover{
        color: #2BC767 !important;
    }

    .topbar-btn:hover{
        background-color: #2BC767;
    }

}

@include media-breakpoint-up(lg) {
    .app-topbar {

        padding: 20px 120px;
    
        .topbar-link {
            padding: 10px 20px !important;
            margin-left: 10px;
            margin-top: 0;

            display: flex;
            align-items: center;
            text-align: center;
        }
        
        .topbar-btn {
            padding: 10px 40px !important;
            margin-top: 0;
            margin-left: 20px;
            display: flex;
            align-items: center;
            text-align: center;
        }
    }
}